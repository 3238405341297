:root {
  --background-primary: #e9d5ff;
  --color-primary: black;
  --color-secondary: rgb(55, 55, 55);
  --color-dash: #99acad;
  --background-reverse: #121212;
  --color-reverse: #f5f7fa;
  --box-shadow: rgba(0, 0, 0, 0.3)
}

.light {
  --background-primary: #e9d5ff;
  --color-primary: black;
  --color-secondary: rgb(66, 66, 66);
  --color-dash: #99acad;
  --background-reverse: #121212;
  --color-reverse: #f5f7fa;
  --box-shadow: rgba(0,0,0,0.3)
}

.dark {
  --background-primary: #121212;
  --color-primary: #f5f7fa;
  --color-secondary: rgb(184, 184, 184);
  --color-dash: #E2FDFF;
  --background-reverse: #E2FDFF;
  --color-reverse: black;
  --box-shadow: rgba(255, 255, 255, 0.3)
}

html {
  background: var(--background-primary);
  color: var(--color-primary);
}

h1,
h2,
p {
  color: var(--color-primary);
}

h4 {
  color: var(--color-secondary);
}

textarea{
  width: 100%;
  height: 1.3em;
  color: var(--color-primary);
  background-color:var(--background-primary);
  font-size: 1.25em;
  border-color: var(--color-dash);
  border-width: 0 0 2px;
  resize:both;
  outline: 0;
  overflow: hidden;
}

textarea:focus{
  border-color:var(--color-primary);
}

textarea::placeholder{
  color: var(--color-dash);
}

input[type=submit]{
  background-color: var(--background-reverse);
  color: var(--color-reverse);
  font-size: 1em;
  border-radius: 5px;
}

@media (hover: none) {
  .my-theme_toggle {
    color: var(--color-primary);
  }
}

body {
  background-color: var(--background-primary);
  color: var(--color-primary);
  font-family: 'Roboto Mono';
  font-weight: 400;
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

a,
a:visited,
a:active,
.theme_toggle,
.theme_toggle:visited,
.theme_toggle:active {
  text-decoration: none;
  color: var(--color-primary);

}

a:hover,
.theme_toggle:hover {
  color: #9BB1FF;
  cursor: pointer;
}

#outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

#inner {
  display: flex;
  align-items: center;
  max-width: 800px;
}

#blog_header_card {
  /* border:1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

#blog_header{
  margin-right: 250px;
  display: flex;
  flex-grow: 9;
}

/* make blog formatting */
#blog{
  /* border: 1px solid blue; */
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-grow: 8;
  max-width: 50%;
  flex-direction: column;
} 


#form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}


#nav_bar {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: flex-end;
  line-height: 35px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 20px;
}

.nav_element {
  color: #9BB1FF !important;
}

#content {
  max-height: max-content;
  flex: 1;
  width: 600px;
  padding-left: 20px;
}

.clickable,
.clickable:visited,
.clickable:active {
  cursor: pointer;
  text-decoration: underline;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  line-height: normal;
  color: var(--color-primary);
}

.clickable:hover,
.credit:hover {
  color: #9BB1FF !important;
}

.credit,
.credit:visited,
.credit:active {
  cursor: pointer;
  text-decoration: underline;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  line-height: normal;
  color: var(--color-primary);

}

.credit_p {
  font-size: small;
  margin-top: -10px;
}

.links {
  letter-spacing: 0.5em;
}

.disclaimer{
  font-size: x-small;
}

.count{
  font-size: x-small;
  text-align: right;
  align-self: flex-start;
  margin-top: -5px;
}

.box{
  outline: 2px solid var(--color-primary);
}